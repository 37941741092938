(function($, WS, GoogleMapsLoader) {
	'use strict';

	/**
	 * @namespace WS
	 */
	if (typeof WS === "undefined") {
		window.WS = {}; WS = window.WS;
	}

	WS.contact_map = function() {
		var my;

		my = {
			ui: {
				$root: $('body'),
				$map: $('.contact-map__map-container'),
				$locations: $('.contact-map__location')
			},

			map: false,

			move_map: function($tab, latlng) {
				// position map
				my.map.setCenter(latlng);
				my.marker.setPosition(latlng);
				// set tab states
				my.ui.$locations.removeClass('active');
				$tab.addClass('active');
			},

			enable: function() {
				if (my.ui.$map.length) {
					//set google maps API key
					GoogleMapsLoader.KEY = '[PLACEHOLDER]';

					// load google maps map
					GoogleMapsLoader.load(function(google) {
						var geocoder = new google.maps.Geocoder(),
							custom_icon;

						// set up custom marker pin
						custom_icon = {
							url: '/includes/dist/images/map-marker.svg',
							anchor: new google.maps.Point(25,50),
							scaledSize: new google.maps.Size(50,50)
						};

						// initialize a map
						my.map = new google.maps.Map(my.ui.$map[0], {
							zoom: 16,
							mapTypeControl: false,
							streetViewControl: false
						});
						// create a marker
						my.marker = new google.maps.Marker({
							map: my.map,
							icon: custom_icon,
							optimized: false
						});

						// set up the locations
						my.ui.$locations.each(function() {
							var $this = $(this),
								loc_address = $(this).attr('data-address'),
								latlng = false;

							geocoder.geocode({address: loc_address}, function(results, status) {
								if (status == 'OK') {
									latlng = results[0].geometry.location;

									// if no location has been set yet, use this one
									if ($this.is('[data-default]')) {
										my.move_map($this, latlng);
									}

									// add click event
									$this.on('click', function() {
										my.move_map($this, latlng);
									});
								}
							});

						});
					});
				}
			}
		};

		my.enable();
	};

	WS.contact_map();

})(window.jQuery, window.WS, window.GoogleMapsLoader);
